




















































import Vue from "vue";
export default Vue.extend({
  name: "UploadFileCard",
  props: {
    file_name: {
      type: String,
      required: true,
      default: "Upload, Drop & paste your resume here for the best AI result"
    },
    hide_paste_cv_btn: {
      type: Boolean,
      default: false
    },
    custom_styling: {
      type: String,
      default: ""
    }
  }
});
