



































import Vue from "vue";
import { ValidateFileType } from "@/interfaces/global_valley/global_valley";
import UploadFileCard from "@/components/skill_extractor/UploadFileCard.vue";
import { mapMutations } from "vuex";
import { SET_ERROR } from "@/store";

export default Vue.extend({
  name: "FileUploader",
  components: { UploadFileCard },
  data() {
    return {
      file: null as null | File,
      name_of_file: "" as string,
      error: false,
      error_message: ""
    };
  },
  props: {
    file_name: {
      type: String,
      required: true
    },
    hide_paste_cv_btn: {
      type: Boolean,
      default: true
    },
    custom_styling: {
      type: String,
      default: "height:500px;padding:0"
    },
    unique_id: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.name_of_file = this.file_name;
  },
  methods: {
    ...mapMutations({
      root_error: SET_ERROR
    }),
    /**
     * Function to open file uploader
     */
    open_file_uploader() {
      document.getElementById(this.unique_id)?.click();
    },
    /**
     * Process file uploading through drag & drop
     * @param e
     */
    add_drop_file(e: DragEvent) {
      if (e && e.dataTransfer && e.dataTransfer.files.length > 0) {
        this.file = e.dataTransfer.files[0];
        this.change_file(this.file);
      }
    },
    /**
     * Validating uploaded file
     * @param value
     */
    change_file(value: File) {
      this.name_of_file = value.name;
      if (this.file) {
        // Validating file size and type
        const valid = this.validate_file(this.file);
        // If file isn't valid => show error alert
        if (!valid.valid) {
          this.error = true;
          this.error_message = valid.msg;
          this.reset_state();

          this.$emit("process_selected_file", null);
          setTimeout(this.close_error_snackbar, 5000);
        } else {
          this.$emit("process_selected_file", this.file);
        }
      }
    },
    reset_state() {
      this.name_of_file = this.file_name;
    },
    /**
     * Validate selected file type: .docs, .pdf & .txt are valid types
     * @param file: File => selected file
     * @return boolean
     */
    validate_file(file: File): ValidateFileType {
      if (file.size > 3e7) {
        return {
          valid: false,
          msg: "File size should be less than 30 MB!"
        };
      } else if (
        file.type === "text/plain" ||
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/doc" ||
        file.type === "application/ms-doc" ||
        file.type === "application/msword"
      ) {
        return {
          valid: true,
          msg: ""
        };
      } else {
        return {
          valid: false,
          msg: "Unsupported file type. .pdf, .docx, .txt files are allowed"
        };
      }
    },
    close_error_snackbar() {
      this.error = false;
      this.error_message = "";
    }
  }
});
