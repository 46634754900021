













































































































































import Vue from "vue";
import {
  CvOptions,
  JobOptions
} from "@/interfaces/cv_job_matching/cv_job_matching";
import AutoCompleteCvSearch from "@/components/cv_job_matching/AutoCompleteCvSearch.vue";
import { Uploads } from "@/interfaces/data_objects/uploads";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import SkillsCard from "@/components/skill_extractor/SkillsCard.vue";
import FileUploader from "@/components/cv_job_matching/FileUploader.vue";
import AddLinkedinLink from "@/components/cv_job_matching/AddLinkedinLink.vue";
import { CvJobAnalysis } from "@/store/modules/cv_job_matching/interfaces";
import { mapMutations } from "vuex";
import { CV_JOB_ANALYSIS_PAYLOAD } from "@/store/modules/cv_job_matching/constants";

export default Vue.extend({
  name: "SelectCvJob",
  components: {
    AddLinkedinLink,
    FileUploader,
    SkillsCard,
    SkillsGroup,
    AutoCompleteCvSearch
  },
  computed: {
    JobOptions() {
      return JobOptions;
    },
    CvOptions() {
      return CvOptions;
    }
  },
  data() {
    return {
      selected_cv_option: CvOptions.SEARCH_CV as CvOptions,
      selected_job_option: JobOptions.UPLOAD_JOB_LINK as JobOptions,
      active_skill_group: "",
      expand_extracted_skills: false,
      cv_options: [
        { title: "Search CV", value: CvOptions.SEARCH_CV },
        { title: "Upload CV", value: CvOptions.UPLOAD_CV }
      ],
      job_options: [
        { title: "Add Linkedin Job", value: JobOptions.UPLOAD_JOB_LINK },
        { title: "Upload Job File", value: JobOptions.UPLOAD_JOB_FILE }
      ],
      file_name:
        "Upload, Drop your resume/syllabi here for the best AI result" as string,
      job_file_name: "Upload or Drop your job file here" as string,
      cv_file: null as null | File,
      job_file: null as null | File,
      searched_cv: null as null | Uploads,
      job_link: null as null | string
    };
  },
  methods: {
    ...mapMutations("cv_job_matching", {
      set_cv_job_analysis_payload: CV_JOB_ANALYSIS_PAYLOAD
    }),
    /**
     * Function to handle cv option change
     * @param value
     */
    update_cv_option(value: CvOptions) {
      if (value === CvOptions.SEARCH_CV) {
        this.reset_cv_state();
      } else {
        this.searched_cv = null;
      }
    },
    /**
     * Function to handle job option change
     * @param value
     */
    update_job_option(value: JobOptions) {
      if (value === JobOptions.UPLOAD_JOB_LINK) {
        this.reset_job_state();
      } else {
        this.job_link = null;
      }
    },
    /**
     * Function to process uploaded cv file
     * @param file
     */
    process_uploaded_cv(file: File | null) {
      this.file_name =
        file?.name ??
        "Upload, Drop your resume/syllabi here for the best AI result";
      this.cv_file = file;
    },
    /**
     * Function to process uploaded job file
     * @param file
     */
    process_uploaded_job(file: File | null) {
      this.job_file_name = file?.name ?? "Upload, Drop your job file here";
      this.job_file = file;
    },
    /**
     * Function to process searched cv file
     * @param val
     */
    process_selected_cv(val: null | Uploads) {
      this.searched_cv = val;
    },
    /**
     * Function tp process uploaded a linkedin link
     * @param link
     */
    process_selected_link(link: null | string) {
      this.job_link = link;
    },
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },

    /**
     * Reset cv state
     */
    reset_cv_state() {
      this.cv_file = null;
      this.file_name =
        "Upload, Drop your resume/syllabi here for the best AI result" as string;
    },
    reset_job_state() {
      this.job_file = null;
      this.job_file_name = "Upload, Drop your job file here" as string;
    },
    cv_job_analysis() {
      const payload: CvJobAnalysis = {
        cv_file: this.cv_file,
        job_file: this.job_file,
        job_link: this.job_link,
        searched_cv: this.searched_cv,
        cv_loading: true,
        job_loading: true,
        compare_gap_loading: true,
        cv_file_response: null,
        job_response: null,
        compare_gap_response: null,
        listing: null,
        fetching_required: true
      };
      this.set_cv_job_analysis_payload(payload);
      this.$router.push("new/analysis");
    }
  }
});
