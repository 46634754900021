





























import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { AUTOCOMPLETE_CV_SEARCH } from "@/store/modules/cv_job_matching/constants";
import { Uploads } from "@/interfaces/data_objects/uploads";
import {
  GET_ANALYZED_RESUMES,
  GETTER_ANALYZED_RESUMES
} from "@/store/modules/skill_extractor/constants";
import { ExistingCvs } from "@/store/modules/cv_job_matching/interfaces";

export default Vue.extend({
  name: "AutoCompleteCvSearch",
  data() {
    return {
      search_input: null,
      selected_searched_cv: null,
      searched_cvs: [] as ExistingCvs[]
    };
  },
  computed: {
    ...mapGetters("skill_extractor", {
      get_analyzed_resumes: GETTER_ANALYZED_RESUMES
    })
  },
  async mounted() {
    const obj = {
      item_per_page: 20,
      page_number: 0,
      top_job: false
    };
    await this.fetch_analyzed_resumes(obj);
    if (this.get_analyzed_resumes.results.length > 0) {
      for (let data of this.get_analyzed_resumes.results) {
        this.searched_cvs.push({
          uploaded_file_name: data.uploaded_file_name,
          value: data
        });
      }
    }
  },
  watch: {
    selected_searched_cv(val) {
      if (val) this.$emit("process_selected_cv", val.value);
      else this.$emit("process_selected_cv", null);
    }
  },
  methods: {
    ...mapActions("cv_job_matching", {
      autocomplete_cv_search: AUTOCOMPLETE_CV_SEARCH
    }),
    ...mapActions("skill_extractor", {
      fetch_analyzed_resumes: GET_ANALYZED_RESUMES // Fetch analyzed resumes with pagination
    }),
    // Filter searched result from backend
    filter_searched_cvs(value: any) {
      this.searched_cvs = [];
      if (value && value.length > 0) {
        for (let data of value) {
          this.searched_cvs.push({
            uploaded_file_name: data.uploaded_file_name,
            value: data
          });
        }
      }
    }
  }
});
