




















import Vue from "vue";
import { get_linkedin_regex } from "@/utils/global";

export default Vue.extend({
  name: "AddLinkedinLink",
  data() {
    return {
      form: false,
      job_link: "",
      rules: [
        (value: string) => {
          if (value) {
            if (get_linkedin_regex().test(value)) return true;
            else return "Invalid Linkedin URL";
          } else return true;
        }
      ]
    };
  },
  watch: {
    job_link(n: string) {
      if (!n || !get_linkedin_regex().test(n)) {
        this.$emit("linkedin_link", null);
      } else {
        this.$emit("linkedin_link", n);
      }
    }
  }
});
