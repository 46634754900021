export enum CvOptions {
  SEARCH_CV,
  UPLOAD_CV
}

export enum JobOptions {
  UPLOAD_JOB_LINK,
  UPLOAD_JOB_FILE
}

export interface ProcessSelectedFile {
  file: File;
  type: UploadedFile;
}

export type UploadedFile = "JOB" | "CV";
